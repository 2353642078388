export const videoConstrain = (deviceId?: string): MediaTrackConstraints => {
    return {
        width: {ideal: 1920},
        height: {ideal: 1080},
        deviceId: deviceId ? { exact: deviceId } : undefined,
        advanced: [{
            // @ts-ignore https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
            resizeMode: 'none',
        }],
    }
};
export const audioConstraints = (deviceId?: string) => {
    return {
        deviceId: deviceId ? { exact: deviceId } : undefined,
        advanced: [{
            noiseSuppression: false,
            echoCancellation: false,
            autoGainControl: false,
            sampleRate: { ideal: 48000 },
            sampleSize: { ideal: 16 },
            channelCount: { ideal: 2 },
        }],
    }
};