import { startCameraByCameraId } from "../helpers/media";

let isCameraActive = false;
let streamId: string | undefined = undefined;
let exitButtonInterval;

export const setListeners = () => {
    cameraListener()
    activateCameraList()
}

export const cameraListener = () => {
    setInterval(() => {
        const _videoElement = document.querySelector('video.wxc-local-media__video')
        if (_videoElement) {
            const videoEl = _videoElement as HTMLVideoElement
            const stream = videoEl.srcObject as MediaStream;
            if (streamId !== stream.id) {
                streamId = stream.id
                const cameraSettings = stream.getVideoTracks()[0].getSettings();
                startCameraByCameraId(cameraSettings.deviceId)
            }
        } else {
            streamId = undefined
        }

    }, 1000);
}

export const activateCameraList = () => {
    setInterval(() => {
        let buttonVideoOn = document.querySelectorAll('svg.wxc-camera-icon')[0] as HTMLVideoElement | undefined;
        if (buttonVideoOn && !isCameraActive) {
            isCameraActive = true;
            console.log('camera fix')
        }
        let buttonVideoOff = document.querySelectorAll('svg.wxc-camera-muted-icon')[0] as HTMLVideoElement | undefined;
        if (buttonVideoOff && isCameraActive) {
            isCameraActive = false;
        }
    }, 1000)
}

export const exitButtonListener = () => {
    exitButtonInterval = setInterval(() => {
        let exitButton = document.querySelector('.wxc-meeting-control__control-button.wxc-button--cancel');
        if (exitButton) {
            exitButton.addEventListener('click', function() {
                console.log('exit event')
                document.location.href = ''
            });
            clearInterval(exitButtonInterval)
        }
    }, 100)
}

export {isCameraActive}
