export const getQueryParam = (name: string) => {
    const q = window.location.search.match(new RegExp(`[?&]${name}=([^&#]*)`));
    // eslint-disable-next-line no-mixed-operators
    return q && q[1] && decodeURI(q[1]) || undefined;
};

export const downloadPhoto = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'captured_image.png';
    downloadLink.style.display = 'block';
    downloadLink.textContent = 'Download Image';
    downloadLink.click()
}

export function getPathFromURL(url: string) {
    try {
        let parsedURL = new URL(url);
        return parsedURL.pathname.slice(1) + parsedURL.search + parsedURL.hash;
    } catch (error) {
        console.error("Invalid URL:", error);
        return undefined;
    }
}

export function getDomainFromURL(url: string) {
    try {
        let parsedURL = new URL(url);
        return parsedURL.hostname;
    } catch (error) {
        console.error("Invalid URL:", error);
        return undefined;
    }
}
