export interface Device {
    isFirefox: boolean;
    isLinux: boolean;
    isMac: boolean;
    isMobile: boolean;
    isSafari: boolean;
    isAndroid: boolean;
    isIos: boolean;
    isWin: boolean;
    isChrome: boolean
}

export const getDevice = (): Device => {
    const device: Device = {
        isMobile: false,
        isAndroid: false,
        isLinux: false,
        isMac: false,
        isWin: false,
        isChrome: false,
        isFirefox: false,
        isSafari: false,
        isIos: false,
    };

    device.isLinux = /linux/i.test(navigator.platform);
    device.isMac = /mac/i.test(navigator.platform);
    device.isWin = /win/i.test(navigator.platform);
    device.isChrome = /chrome|crios/i.test(navigator.userAgent);
    device.isFirefox = /firefox|fxios/i.test(navigator.userAgent);
    device.isSafari = !device.isChrome && !device.isFirefox
        ? /safari/i.test(navigator.userAgent)
        : false;
    device.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
        navigator.userAgent,
    );
    device.isAndroid = /Android/i.test(navigator.userAgent);
    device.isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (!device.isIos) {
        const isIPad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
        if (isIPad) {
            device.isIos = true;
            device.isMobile = true;
        }
    }

    return device;
};

export const isChrome = () => getDevice().isChrome