export interface CheckResponseDto {
    score: number;
    quality: number;
    probability: number;
    error?: string;
}

export interface CheckResultDto {
    participantId: string;
    result: CheckResponseDto;
    localDateTime: string;
    type: keyof typeof CheckType;
}

export enum CheckType {
    FACE_DEEPFAKE = 'FACE_DEEPFAKE',
    FACE_LIVENESS = 'FACE_LIVENESS',
    VOICE_LIVENESS = 'VOICE_LIVENESS',
    VOICE_CLONE = 'VOICE_CLONE',
}