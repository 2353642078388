import axios from "axios";
import { CheckResultDto } from "@/dto/CheckResultDto";
import {appConfig} from "../app.config";
import { AccessToken } from "@/dto/AccessToken";
import { GuestAccessToken } from "@/dto/GuestAccessToken";

const backendUrl = appConfig.BACKEND_URL

let faceRequestAlreadySend = false;

export const checkFace = async (sessionId: string, participantId: string, file: Blob): Promise<void> => {
    if (faceRequestAlreadySend || file === null) return
    const formData = new FormData();
    // downloadPhoto(file)
    formData.append('file', file)
    faceRequestAlreadySend = true;
    await axios.post(backendUrl + '/check/face', formData, {
        params: {
            sessionId, participantId
        },
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).finally(() => {
        faceRequestAlreadySend = false;
    })
}

export const checkVoice = async (sessionId: string, participantId: string, file: Blob): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file)

    await axios.post(backendUrl + '/check/voice', formData, {
        params: {
            sessionId, participantId
        },
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

export const getCheckResults = async (sessionId: string): Promise<CheckResultDto[]> => {
    return axios.get(backendUrl + '/check/results/', {
        params: { sessionId },
    }).then(res => res.data);
}

export const getAccessToken = async (code: string): Promise<AccessToken> => {
    return axios.get(
        backendUrl + '/webex/access-token',
        { params: { code } })
        .then(res =>res.data)
}

export const getGuestAccessToken = async (displayName: string): Promise<GuestAccessToken> => {
    return axios.get(
        backendUrl + '/webex/access-token/guest',
        { params: { displayName } })
        .then(res =>res.data)
}
