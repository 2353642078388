import React from "react";
import { render } from "react-dom";
import './react-app-env.d.ts'
import "./index.css";
import App from "./App.tsx";
import 'react-toastify/dist/ReactToastify.css';
import '@webex/widgets/dist/css/webex-widgets.css';

const root = document.getElementById("root");
render(<App />, root);

