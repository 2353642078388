import React, { useCallback, useEffect, useMemo } from 'react';
import './CheckResults.css';
import {useState} from "react";
import { CheckResultDto, CheckType } from "../dto/CheckResultDto";
import { Participant, ParticipantWithCheck } from "../dto/Participant";
import { useOutsideClick } from "../hooks/useOutsideClick";
import faceFrown from "../assets/face-frown.svg";
import faceSmile from "../assets/face-smile.svg";
import faceNeutral from "../assets/face-neutral.svg";
import warningIcon from "../assets/warning.svg";

interface CheckResultsProps {
    readonly meetingUsers: Participant[];
    readonly checkResults: CheckResultDto[];
}

function CheckResults(props: CheckResultsProps) {
    const [deepfake, setDeepfake] = useState<CheckResultDto | undefined>(undefined);
    const [faceLiveness, setFaceLiveness] = useState<CheckResultDto | undefined>(undefined);
    const [voiceClone, setVoiceClone] = useState<CheckResultDto | undefined>(undefined);
    const [voiceLiveness, setVoiceLiveness] = useState<CheckResultDto | undefined>(undefined);
    const [isDetailsOpen, setDetailsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const ref = useOutsideClick(closeDetails);

    function openDetails(userId: string) {
        setDetailsOpen(true);
        setSelectedUser(userId)
    }

    const getDeepfakeByUserId = (userId: string) => {
        return props.checkResults
            .filter(r => r.participantId === userId)
            .find((r: CheckResultDto) => r.type === CheckType.FACE_DEEPFAKE) as CheckResultDto;
    }

    function isLive(probability: number) {
        return probability > 0.5
    }

    function closeDetails() {
        setDetailsOpen(false);
        setSelectedUser('')
    }

    function isUserSelected(userId: string) {
        return selectedUser === userId;
    }

    const displayResults = useMemo<ParticipantWithCheck[]>(
        () => {
            const users = props.meetingUsers
                .map(mu => ({
                    ...mu,
                    results: props.checkResults.filter(r => r.participantId === mu.participant.identity),
                    isActiveSpeaker: false
                } as ParticipantWithCheck) )
            users.sort()
            users.sort((u1) => u1.isActiveSpeaker ? -1 : 1)
            return users
        }, [props.meetingUsers, props.checkResults]);


    const userResults = useMemo<CheckResultDto[]>(() => {
        let checkResultDtos = props.checkResults.filter(r => r.participantId === selectedUser);
        console.log(checkResultDtos, props.checkResults, selectedUser)
        return checkResultDtos
    }, [props.checkResults, selectedUser])

    useEffect(() => {
        setDeepfake(userResults.find(r => r.type === CheckType.FACE_DEEPFAKE))
        setFaceLiveness(userResults.find(r => r.type === CheckType.FACE_LIVENESS))
        setVoiceClone(userResults.find(r => r.type === CheckType.VOICE_CLONE))
        setVoiceLiveness(userResults.find(r => r.type === CheckType.VOICE_LIVENESS))
    }, [userResults]);


    return (
        <div className="liveness-checks__wrapper">
            <div className="liveness-checks">
                <div className="check-title"><strong>Participants:</strong></div>
                {displayResults.map(result =>
                    <div
                        onClick={() => openDetails(result.participant.identity)}
                        key={result.id}
                        className={`user-status ${isUserSelected(result.id) ? 'user-status_selected' : ''}`}
                    >
                        <div className="user-status_title">
                            {result.name}
                            {getDeepfakeByUserId(result.participant.identity)?.result.probability && isLive(getDeepfakeByUserId(result.participant.identity)!.result.probability) && (
                                <img className="face-check__image" src={faceSmile}/>
                            )}
                            {getDeepfakeByUserId(result.participant.identity)?.result.probability && !isLive(getDeepfakeByUserId(result.participant.identity)!.result.probability) && (
                                <img className="face-check__image" src={faceFrown}/>
                            )}
                            {getDeepfakeByUserId(result.participant.identity)?.result.error && (
                                <img className="face-check__image" src={warningIcon}/>
                            )}
                            {!getDeepfakeByUserId(result.participant.identity) && (
                                <img className="face-check__image" src={faceNeutral}/>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {isDetailsOpen && (
                <div className="check-details" ref={ref}>
                    <p className="check-details__title">Face:</p>
                    <p>
                        Deepfake -
                        <strong
                            className={`
                        ${deepfake?.result.probability && isLive(deepfake!.result.probability) ? 'check_live' : ''}
                        ${deepfake?.result.probability && !isLive(deepfake!.result.probability) ? 'check_spoof' : ''}
                    `}
                        >
                            {deepfake?.result.probability ? (isLive(deepfake!.result.probability) ? 'NO' : 'YES') : 'N/A'}
                        </strong>
                    </p>
                    {/*<p>*/}
                    {/*    Spoof -*/}
                    {/*    <strong*/}
                    {/*        className={`*/}
                    {/*    ${faceLiveness?.result.probability && isLive(faceLiveness!.result.probability) ? 'check_live' : ''}*/}
                    {/*    ${faceLiveness?.result.probability && !isLive(faceLiveness!.result.probability) ? 'check_spoof' : ''}*/}
                    {/*`}*/}
                    {/*    >*/}
                    {/*        {faceLiveness?.result.probability ? (isLive(faceLiveness!.result.probability) ? 'NO' : 'YES') : 'N/A'}*/}
                    {/*    </strong>*/}
                    {/*</p>*/}
                    {deepfake?.result.error && <p>Deepfake error - {deepfake.result.error}</p>}
                    {/*{faceLiveness?.result.error && <p>Face liveness error - {faceLiveness.result.error}</p>}*/}

                    <p className="check-details__title">Voice:</p>
                    <p>
                        Clone -
                        <strong
                            className={`
                        ${voiceClone?.result.probability && isLive(voiceClone!.result.probability) ? 'check_live' : ''}
                        ${voiceClone?.result.probability && !isLive(voiceClone!.result.probability) ? 'check_spoof' : ''}
                    `}
                        >
                            {voiceClone?.result.probability ? (isLive(voiceClone!.result.probability) ? 'NO' : 'YES') : 'N/A'}
                        </strong>
                    </p>
                    {/*<p>*/}
                    {/*    Replay -*/}
                    {/*    <strong*/}
                    {/*        className={`*/}
                    {/*    ${voiceLiveness?.result.probability && isLive(voiceLiveness!.result.probability) ? 'check_live' : ''}*/}
                    {/*    ${voiceLiveness?.result.probability && !isLive(voiceLiveness!.result.probability) ? 'check_spoof' : ''}*/}
                    {/*`}*/}
                    {/*    >*/}
                    {/*        {voiceLiveness?.result.probability ? (isLive(voiceLiveness!.result.probability) ? 'NO' : 'YES') : 'N/A'}*/}
                    {/*    </strong>*/}
                    {/*</p>*/}

                    {voiceClone?.result.error && <p>Clone error - {voiceClone.result.error}</p>}
                    {/*{voiceLiveness?.result.error && <p>Liveness error - {voiceLiveness.result.error}</p>}*/}

                </div>
            )}
        </div>
    );
}

export default CheckResults;
