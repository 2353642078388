import logo from './logo.png';
import './App.css';
import {WebexMeetingsWidget} from '@webex/widgets';
import {useCallback, useEffect, useState} from "react";
import {getQueryParam} from "./helpers/url";
import { checkFace, getAccessToken, getGuestAccessToken, getCheckResults, checkVoice } from "./api";
import {
    dropCookie,
    getAccessTokenFromCookie, getDisplayNameFromCookie,
    setAccessTokenToCookie,
    setDisplayNameToCookie
} from "./helpers/cookies";
import {getPhoto, prepareStreams} from "./helpers/media";
import { exitButtonListener, isCameraActive, setListeners } from "./helpers/listeners";
import { getUserDetails, initWebex, onMeeting } from "./helpers/webex";
import React from 'react';
import { Participant, ParticipantWithCheck } from "./dto/Participant";
import { toast, ToastContainer } from 'react-toastify';
import { appConfig } from "./app.config";
import { activateMicroList, isMicroActive, onChangeMicroListener, setVoiceCallback } from "./helpers/voiceMedia";
import CheckResults from "./components/CheckResults";
import { CheckResultDto } from "./dto/CheckResultDto";


function App() {
    const [accessToken, setAccessToken] = useState(getAccessTokenFromCookie());
    const [anonymousDisplayName, setAnonymousDisplayName] = useState(getDisplayNameFromCookie());
    const [meetingDestination, setMeetingDestination] = useState<string>(getQueryParam('meeting_destination'));
    const [displayName, setDisplayName] = useState("");
    const [meetingStarted, setMeetingStarted] = useState(false);
    const [participantId, setParticipantId] = useState<string>('');
    const [participants, setParticipants] = useState<Participant[]>([])
    const [checkResults, setCheckResults] = useState<CheckResultDto[]>([])
    const [code, setCode] = useState(getQueryParam('code'))


    const tryCheckFace = useCallback(async () => {
        if (isCameraActive && onMeeting) {
            const photo = await getPhoto();
            console.log('photo', photo)
            if (photo && meetingDestination && participantId) {
                checkFace(meetingDestination, participantId, photo);
            } else {
                console.error('blob or meetingDestination or participantId not found', photo, meetingDestination, participantId);
            }
        }
    }, [meetingDestination, participantId]);

    const checkVoiceCallback = async (wav: Blob, participantId: string, meetingDestination: string) => {
        if (isMicroActive && onMeeting) {
            console.log('participantId', participantId)
            if (wav && meetingDestination && participantId) {
                checkVoice(meetingDestination, participantId, wav)
            } else {
                console.error('wav or meetingDestination or participantId not found', wav, meetingDestination, participantId);
            }
        }
    };

    const getCheckLiveness = useCallback(async () => {
        if (onMeeting) {
            if (meetingDestination) {
                setCheckResults(await getCheckResults(meetingDestination))
            } else {
                console.error('meetingDestination not found', meetingDestination);
            }
        }
    }, [meetingDestination, participants]);

    useEffect(() => {
        const intervalTryCheckLiveness = setInterval(tryCheckFace, 5000);
        const intervalGetCheckLiveness = setInterval(getCheckLiveness, 2000);
        return () => {
            clearInterval(intervalTryCheckLiveness);
            clearInterval(intervalGetCheckLiveness);
        }
    }, [tryCheckFace, getCheckLiveness]);

    async function logout() {
        dropCookie()
        setAccessToken("")
        setDisplayName("")
        setAnonymousDisplayName("")
        setCode('')
        window.history.replaceState({}, document.title, "/")
    }

    async function loginAsGuest() {
        if (displayName) {
            try {
                const token = await getGuestAccessToken(displayName);
                console.log('guest token', token)
                setAccessToken(token.accessToken)
                setDisplayNameToCookie(token.displayName)
                setAccessTokenToCookie(token.accessToken)
                toast.info('Successful login as guest')
            } catch (e) {
                console.error(e)
                toast.error(e?.response?.data?.message || 'Unknown error')
            }
        }
    }

    async function joinMeeting() {
        try {
            if(!meetingDestination) {
                const meetingUrl = getQueryParam('meeting_destination');
                setMeetingDestination(meetingUrl)
                if (!meetingUrl) return console.error('meeting_destination not found')
            }

            if(!accessToken) {
                if (!code) {
                    toast.error('Token not found, follow authentication link')
                    return console.error('code not found')
                }

                try {
                    const token = await getAccessToken(code)

                    console.log('token', token)

                    setAccessTokenToCookie(token.access_token)
                    setAccessToken(token.access_token)
                    console.log(accessToken, token)
                    initWebex(token.access_token)
                } catch (e) {
                    toast.error('En error getting token')
                    return console.error(e)
                }

            }
            if (accessToken) await initWebex(accessToken)

            await prepareStreams()
            setListeners()
            setMeetingStarted(true)

            const participantId = await getUserDetails(setParticipants)
            setParticipantId(participantId as string)
            // @ts-ignore
            setVoiceCallback((wav) => checkVoiceCallback(wav, participantId, meetingDestination))
            onChangeMicroListener()
            activateMicroList()
            exitButtonListener()

        } catch (e) {
            console.error(e)
            setAccessToken('')
            setMeetingDestination('')
            setMeetingStarted(false)
        }
    }

  return (
    <div className="App">
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            {!accessToken && !code ? (
                <div className="btn-control">
                    {appConfig.AUTH_URL && (
                        <a href={appConfig.AUTH_URL} className="btn-control_sign-in custom-btn">sign in</a>
                    )}
                    <div className="divider"></div>
                    <input
                        placeholder={'Display name'}
                        className={'custom-input'}
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                    />
                    <button
                        className={`custom-btn ${!displayName ? 'join-btn_disabled' : ''}`}
                        onClick={loginAsGuest}
                        disabled={!displayName}
                    >
                        login as guest
                    </button>
                </div>
            ) : (
                <div>
                    {!!anonymousDisplayName && (
                        <div className="guest-name">Guest - {anonymousDisplayName}</div>
                    )}

                    <input
                        placeholder={'Meeting destination'}
                        className={'custom-input'}
                        value={meetingDestination}
                        onChange={e => setMeetingDestination(e.target.value)}
                    />
                    <div className="btn-control">
                        <button
                            className={`custom-btn ${!meetingDestination ? 'join-btn_disabled' : ''}`}
                            onClick={joinMeeting}
                            disabled={!meetingDestination}
                        >
                            join meeting
                        </button>
                        <button
                            className={`custom-btn logout-btn`}
                            onClick={logout}
                        >
                            logout
                        </button>
                    </div>
                </div>
            )}

            {meetingStarted && (
                <WebexMeetingsWidget
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                    }}
                    accessToken={accessToken}
                    meetingDestination={meetingDestination}
                />
            )}
            {onMeeting && (
                <CheckResults meetingUsers={participants} checkResults={checkResults} />
            )}

        </header>
        <ToastContainer />
    </div>
  );
}

export default App;
