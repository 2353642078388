import Cookies from "js-cookie";
import { getDomainFromURL } from "../helpers/url";

let currentDomain = getDomainFromURL(window.location.href);

const accessTokenPropertyName = `${currentDomain}_access_token`
const displayNamePropertyName = `${currentDomain}_display_name`

const ONE_DAY_EXPIRATION_TIME = 60 * 60 * 24;

export const COOKIES_CONFIG = (expiresIn: number) => {
    return {path: '/', domain: currentDomain, expires: expiresIn}
};

export function setAccessTokenToCookie(accessToken: string, expiresIn: number = ONE_DAY_EXPIRATION_TIME) {
    console.log('accessTokenPropertyName', accessTokenPropertyName)
    Cookies.set(accessTokenPropertyName, accessToken, COOKIES_CONFIG(expiresIn));
}

export function setDisplayNameToCookie(displayName: string, expiresIn: number = ONE_DAY_EXPIRATION_TIME) {
    Cookies.set(displayNamePropertyName, displayName, COOKIES_CONFIG(expiresIn));
}

export function dropCookie() {
    console.log('remove cookies')
    Cookies.remove(accessTokenPropertyName, COOKIES_CONFIG(ONE_DAY_EXPIRATION_TIME));
    Cookies.remove(displayNamePropertyName, COOKIES_CONFIG(ONE_DAY_EXPIRATION_TIME));
}

export function getAccessTokenFromCookie() {
    return Cookies.get(accessTokenPropertyName);
}
export function getDisplayNameFromCookie() {
    return Cookies.get(displayNamePropertyName);
}