import { toast } from 'react-toastify';

import { isChrome } from "./device";
let videoElement: HTMLVideoElement | undefined = undefined;


const videoConstrain = (cameraId?: string): MediaTrackConstraints => {
    return {
        width: {ideal: 1920},
        height: {ideal: 1080},
        deviceId: cameraId ? { exact: cameraId } : undefined,
        advanced: [{
            // @ts-ignore https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
            resizeMode: 'none',
        }],
    }
};

export async function prepareStreams() {
    if(isChrome())
        await navigator.mediaDevices.getUserMedia({ video: videoConstrain(), audio: true })

    const cameras = await getVideoDevices()
    console.log(cameras)
    for (const cam of cameras) {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: videoConstrain(cam.deviceId) });
            const cameraSettings = stream.getVideoTracks()[0].getSettings();
            console.log(cameraSettings)
        } catch (e) {
            console.error(e)
            toast.error(`Camera ${cam.label} cannot be started`)
        }
    }
}

export function getPhoto(): Promise<Blob | null> {
    return new Promise(resolve => {
        const canvas = createCanvasFromVideo()
        if (canvas) {
            canvas.toBlob((blob) => {
                    resolve(blob)
                },
                "PNG"
            );
        } else {
            return resolve(null)
        }
    });
}

const createCanvasFromVideo = () => {
    if (videoElement === null) return console.error('videoElement not found')
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    const dx = 0;
    const dy = 0;
    const dw = canvas.width;
    const dh = canvas.height;
    context.drawImage(videoElement, dx, dy, dw, dh, 0, 0, dw, dh);

    return canvas;
};

async function getVideoDevices() {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === 'videoinput');
    } catch (error) {
        console.error('Error enumerating devices:', error);
        return [];
    }
}

export async function startCameraByCameraId(cameraId?: string) {
    try {
        if (!cameraId) {
            const devices = await getVideoDevices()
            if (devices.length) {
                console.log(devices[0])
                cameraId = devices[0].deviceId
            }
        }
        const stream = await navigator.mediaDevices.getUserMedia({
            video: videoConstrain(cameraId)
        });
        videoElement = document.createElement('video');
        videoElement.srcObject = stream;

        const cameraSettings = stream.getVideoTracks()[0].getSettings();
        console.log('init stream', stream, cameraSettings)

        if(cameraSettings.height && cameraSettings.height < 720) {
            toast.error(
                `Small resolution ${cameraSettings.width}x${cameraSettings.height}`,
                {
                        autoClose: false,
                        theme: "colored",
                    }
                )
        }

        return await videoElement.play()
    } catch (e: any) {
        console.error(e)
    }
}