import { Participant } from "@/dto/Participant";
import { init } from 'webex';

let onMeeting = false;

let webexClient = null;

enum UserStatusOnMeeting {
    NOT_IN_MEETING = "NOT_IN_MEETING",
    IN_MEETING = "IN_MEETING"
}

export const initWebex = async (accessToken: string) => {
    webexClient = init({
        credentials: {
            access_token: accessToken
        }
    });
    await webexClient.meetings.unregister();
}

export const getUserDetails = async (
    updateParticipantCallback: (participant: Participant[]) => void,
) : Promise<String> => {
    return new Promise(async (resolve) => {
        try {
            await webexClient.meetings.register();
            console.log('Meetings registered successfully');
            await webexClient.meetings.syncMeetings()

            webexClient.meetings.on('meeting:added', (addedMeeting) => {
                console.log('Meeting added:', addedMeeting);
                const userId = addedMeeting.meeting.userId;
                console.log('userId', userId);
                console.log('Meeting members:', addedMeeting.meeting.members.membersCollection.members);

                resolve(userId)

                addedMeeting.meeting.members.on('members:update', (payload) => {
                    console.log('payload', payload)
                    updateParticipantCallback(Object.keys(payload.full).map(k => payload.full[k]).filter(u => u.status === UserStatusOnMeeting.IN_MEETING))

                    const currentUser = payload.delta.updated.find(u => u.participant.identity === userId)
                    if (currentUser && currentUser.status === UserStatusOnMeeting.IN_MEETING && !onMeeting) {
                        console.log('join to meeting')
                        onMeeting = true
                    }
                })
            });

        } catch(error) {
            console.error(error);
            throw Error('Error fetching user details')
        }
    })
}

export {onMeeting}
