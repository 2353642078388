export interface AppConfig {
  BACKEND_URL: string;
  AUTH_URL: string;
}

const defaultConfig: AppConfig = {
    BACKEND_URL: 'http://localhost:8080',
    AUTH_URL: '',
};

let appConfig: AppConfig = {
    BACKEND_URL: 'APP_BACKEND_URL',
    AUTH_URL: 'APP_AUTH_URL',
};

for (const key in appConfig) {
    if (Object.prototype.hasOwnProperty.call(appConfig, key)) {
        // @ts-ignore
        if (appConfig[key] === key.replace('APP_', '')) {
            // @ts-ignore
            appConfig[key] = defaultConfig[key];
        }
    }
}

if (process.env.NODE_ENV === 'development') {
    appConfig = {
        BACKEND_URL: 'http://localhost:8080',
        AUTH_URL: 'https://webexapis.com/v1/authorize?client_id=C7582d0056b4d957b0e911f4c33f71a46055d295b46cffca38ddea91a2d0d1e86&response_type=code&redirect_uri=https%3A%2F%2Fwebex-demo.idrnd.net&scope=spark%3Akms&state=set_state_here',
    };
}

console.log(appConfig)

export { appConfig };
