import { toast } from 'react-toastify';
import { IDVoiceCapturingModule } from "./IDVoiceCapturingModule.js";

let selectedMicroName: string | undefined = undefined;

let voiceDropDownActivated = false;
let capturingModule: IDVoiceCapturingModule | null = null;
let voiceCheckCallback: ((wav: Blob) => void) | null = null;
export let isMicroActive = false;

export async function startMicro() {
    if (capturingModule) capturingModule.destroy()
    const micros = await getMicroDevices()
    const microByName = micros.find(c => c.label === selectedMicroName);
    const microId = microByName ? microByName.deviceId : undefined;
    console.log('start micro', microId)
    capturingModule = new IDVoiceCapturingModule(
        3000,
        0,
        microId,
        () => capturingModule.startRecording(),
        (speechLength: number) => {
            console.log('passSpeechLength', speechLength);
        },
        startCheckingRecord,
        onInitialized,
        onError,
    );
}

export function stopMicro() {
    if (capturingModule) capturingModule.destroy()
}

const onError = (e: any) => {
    console.error(e)
    toast.error(e?.response?.data?.message || 'Unknown error')
}
const onInitialized = () => {
    console.log('voice module initialized')
}

const startCheckingRecord = (wav: Blob) => {
    console.log('complete Record', wav)
    if (voiceCheckCallback) {
        voiceCheckCallback(wav)
    } else {
        console.error('voice callback not found')
    }
    capturingModule.startRecording()
}

export function setVoiceCallback(fun: (wav: Blob) => void) {
    voiceCheckCallback = fun
}

export function onChangeMicroListener() {
    setInterval(async () => {
        let selectMicroElement = document.querySelector('.wxc-dropdown__control [title="Audio Devices"]');
        // console.log('audio element', selectMicroElement)
        if(selectMicroElement) {
            const selectedCameraElement = selectMicroElement.querySelector('span');
            const cameraName = selectedCameraElement.textContent.replace('Default - ', '')
            if (selectedMicroName !== cameraName) {
                selectedMicroName = cameraName
                if (isMicroActive) startMicro()
            }
        }
    }, 100)
}

async function getMicroDevices() {
    try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === 'audioinput');
    } catch (error) {
        console.error('Error enumerating devices:', error);
        return [];
    }
}

export const activateMicroList = () => {
    setInterval(async () => {
        let buttonOn = document.querySelectorAll('button[aria-label="Mute"]')[0] as HTMLVideoElement | undefined; // todo fix
        if (buttonOn && !isMicroActive) {
            isMicroActive = true;
            console.log('isMicroActive', isMicroActive)
            await startMicro()
        }
        let buttonOff = (document.querySelectorAll('button[aria-label="Unmute"]')[0] || document.querySelectorAll('svg.SvgAudioUnmuteHovered')[0]) as HTMLVideoElement | undefined; // todo fix
        if (buttonOff && isMicroActive) {
            isMicroActive = false;
            console.log('isMicroActive', isMicroActive)
            stopMicro()
        }
    }, 100)
}
